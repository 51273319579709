import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {
    isBusyLoading: true,
    orderInfo: null,
  },
  getters: {},
  mutations: {
    UPDATE_BUSY_LOADING_STATE(state, val) {
      state.isBusyLoading = val
    },
    UPDATE_ORDER_INFO_STATE(state, val) {
      state.orderInfo = val
    },
  },
  actions: {
    // (查詢)訂單列表
    getOrderList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/order', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)單一訂單
    getOrderData(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/order/${resolveData.order_id}`, { headers: { 'Access-Token': useJwt.getToken() }, params: resolveData.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)單一訂單
    getOrderProductSearch(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/order/product/${resolveData.type}`, { headers: { 'Access-Token': useJwt.getToken() }, params: resolveData.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)訂單
    setOrderCreate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/order', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)訂單
    setOrderUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/order/${resolveData.order_id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (取消/退款/完成)訂單
    setOrderCompleted(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/order/${resolveData.order_id}/completed`, {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (手動)付款訂單/確認訂單
    setOrderPay(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/order/${resolveData.order_id}/pay`, {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)單一訂單歷史紀錄
    getOrderHistory(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/order/${resolveData.order_id}/log`, { headers: { 'Access-Token': useJwt.getToken() }, params: resolveData.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // --------------------------------手動觸發串接--------------------------------------
    // (網域)
    setOrderServiceDomain(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/domainName/${resolveData.domain_name_id}/action`, {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)會員購物車列表
    getCustomerOrderList(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/customer/${resolveData.customer_id}/order`, { headers: { 'Access-Token': useJwt.getToken() }, params: resolveData.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
